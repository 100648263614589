





















































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import Share from '@/components/Share/index.vue'
import { getYearPlanDetail, exportYearPlan } from '@/api/year'
@Component({
  components: {
    Commit,
    ThumbsUp,
    Share,
  },
})
export default class showYearPlan extends Vue {
  timeYear: string = moment().format('YYYY')
  orignYear: string = ''
  open: boolean = false
  fileList: Object[] = [
    {
      name: '1.jpg',
    },
  ]
  year: any = ''
  id: string = ''
  PlanDetailData: any = {}
  currentCommitList: any = []
  shareList: any = []
  shareShow: boolean = false
  loading: boolean = false
  disabled: boolean = false
  get getRole() {
    return this.$store.state.user.userInfo.userType
  }
  get getApiToken() {
    return this.$store.state.user.userInfo.apiToken
  }
  mounted() {
    if (this.$route.query.year) {
      this.year = this.$route.query.year
    }
    this.getPlanDetail()
  }
  openChange(val: any) {
    if (val) {
      this.open = true
    } else {
      this.open = false
    }
  }
  panelChange(val: any) {
    this.timeYear = moment(val).format('YYYY')
    this.year = this.timeYear
    this.open = false
    //调取接口获取数据
    this.getPlanDetail()
  }
  disabledDate(value: any) {
    return new Date(value).getFullYear() > new Date().getFullYear() + 1
  }
  currentYearPlan() {
    this.year = new Date().getFullYear()
    this.timeYear = this.year
    this.getPlanDetail()
  }
  async getPlanDetail() {
    const [err, data] = await getYearPlanDetail(this.year)
    if (data) {
      this.PlanDetailData = data
      if (data.businessAttachmentList === null) {
        this.PlanDetailData.businessAttachmentList = []
      }
      this.timeYear = this.PlanDetailData.year
      this.orignYear = this.PlanDetailData.year
      this.id = this.PlanDetailData.id
      this.currentCommitList = this.PlanDetailData.commentRecordsList
      this.shareList = this.PlanDetailData.shareList
      this.shareShow = true
    } else {
      if (
        this.year == new Date().getFullYear() ||
        this.year == new Date().getFullYear() + 1
      ) {
        this.$router.push({
          path: '/year/addYearPlan',
          query: { createYear: this.year },
        })
      } else {
        this.year = this.orignYear
        this.$message.error('无该年份计划')
      }
    }
  }
  //导出计划
  async handleExport(id: string) {
    this.loading = true
    await exportYearPlan(id)
    this.loading = false
  }
  editYearPlan(year: string, id: string) {
    this.$router.push({
      path: '/year/addYearPlan',
      query: { year: year, id: id },
    })
  }
}
